define('SomethingDigital_CustomerSpecificPricing/js/view/types/simple',[
    'jquery'
], function ($) {
    return function (data, currencySymbol) {
        var sku = $('[itemprop=sku]').text();
        if (data[sku] !== undefined && data[sku]['price'] !== undefined) {
            var price = data[sku]['price'];
            if (price !== null && price !== 0) {
                $('.product-info-main div.price-final_price > span:not(.old-price) .price .price_message').text(currencySymbol + price);
            }
        }
        setTimeout(function(){ 
            $('.price-container').show();
            $('.priceDesc').show();
        }, 3000);
    } 
});
