/*jshint browser:true jquery:true*/
/*global alert*/
define('ShipperHQ_Option/js/model/cart/cache-mixin',[
    'underscore',
    'mage/utils/wrapper'
], function (_, wrapper) {
    'use strict';

    return function (cacheConstructor) {
        cacheConstructor.requiredFields.push('city');
        cacheConstructor.requiredFields = _.uniq(cacheConstructor.requiredFields);
        return cacheConstructor;
    };
});
