/**
 * Copyright © Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

define('Magento_GiftCardAccount/js/model/payment/gift-card-messages',[
    'ko',
    'Magento_Ui/js/model/messages'
], function (ko, Messages) {
    'use strict';

    return new Messages();
});

